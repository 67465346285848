.App{
    min-height: 100vh;
    display: flex;
    flex-direction: column;
}
header{
    height: auto;
}
footer{
    margin-top: auto;
}

@media screen and (max-width:900px) {
    .route{
    margin:auto 0;
}
}