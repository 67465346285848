
@import url("https://fonts.googleapis.com/css2?family=Lato:wght@400;700&family=Montserrat:wght@700&display=swap");

:root {
  /* Colors */
  --brand-color: hsl(46, 100%, 50%);
  --black: hsl(0, 0%, 0%);
  --white: hsl(0, 0%, 100%);
  /* Fonts */
  --font-title: "Montserrat", sans-serif;
  --font-text: "Lato", sans-serif;
}

/* RESET */

/* Box sizing rules */
.hh2{
  margin: 30px;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  font-size: 40px;
  letter-spacing: 3px;
  font-family: 'Times New Roman',serif;
  font-weight: bold;
  background-image: linear-gradient(
   to right,
   #a17e50 0,
   #cb9b51 22%, 
#f6e27a 45%,
#e0b976 50%,
#f6e27a 55%,
#cb9b51 78%,
#a17e50 100%
   );
  color:transparent;
  -webkit-background-clip:text;
}

.card__title{
  font-size: 2.25rem;
  font-family: var(--font-title);
  color: var(--white);
  line-height: 1.1;
}

.card__description {
  font-family: var(--font-text);
  font-size: 1rem;
  line-height: 1.5;
  color: var(--white);
}

.flow > * + * {
  margin-top: var(--flow-space, 1em);
}

/* CARD COMPONENT */
.cardo{
    margin-top: -50px;
    display: flex; justify-content: center;
    flex-wrap: wrap;
  align-items: center;
  
}
.card {
    
  display:grid ;
 margin: 60px;
 
  place-items: center;
  width: 80vw;
  max-width: 21.875rem;
  height: 410px;
  overflow: hidden;   justify-content: center;
  border-radius: 0.625rem;
  box-shadow: 0.25rem 0.25rem 0.5rem rgba(0, 0, 0, 0.25);
}


.card > * {
  grid-column: 1 / 2;
  grid-row: 1 / 2;
}

.card__background {
  object-fit: cover;

  max-width: 100%;
  height: 100%;
}

.card__content {
  --flow-space: 0.9375rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-self: flex-end;
  height: 55%;
  padding: 12% 1.25rem 1.875rem;
  background: linear-gradient(
    180deg,
    hsla(0, 0%, 0%, 0) 0%,
    hsla(0, 0%, 0%, 0.3) 10%,
    hsl(0, 0%, 0%) 100%
  );
}

.card__content--container {
  --flow-space: 1.25rem;
}

.card__title {
  position: relative;
  width: fit-content;
  width: -moz-fit-content; /* Prefijo necesario para Firefox  */
  font-family: 'Times New Roman',serif;
  font-weight: bold;
  background-image: linear-gradient(
    to right,
    #a17e50 0,
    #cb9b51 22%, 
 #f6e27a 45%,
 #e0b976 50%,
 #f6e27a 55%,
 #cb9b51 78%,
 #a17e50 100%
    );
   color:transparent;
   -webkit-background-clip:text;
}

.card__title::after {
  content: "";
  position: absolute;
  height: 0.3125rem;
  width: calc(100% + 1.25rem);
  bottom: calc((1.25rem - 0.5rem) * -1);
  left: -1.25rem;
  background-color: var(--brand-color);
}

.card__button {
    margin-top: -20px;
  padding: 0.75em 1.6em;
  width: fit-content;
  width: -moz-fit-content; /* Prefijo necesario para Firefox  */
  font-weight: bold;
  border-radius: 0.45em;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 30px;
  background-color: var(--brand-color);
  font-family: var(--font-title);
  font-size: 1.125rem;
  color: var(--black);
}
@media only screen and (max-width:900px){
  .card {
    
    display:grid ;
   margin: 30px;
   margin-top: 60px;
    place-items: center;
    width: auto;
    max-width: 21.875rem;
    height: 410px;
    overflow: hidden;   justify-content: center;
    border-radius: 0.625rem;
    box-shadow: 0.25rem 0.25rem 0.5rem rgba(0, 0, 0, 0.25);
  }
  
    .card__button{
        margin-top: -27px;

    }
}

.card__button:focus {
  outline: 2px solid black;
  outline-offset: -5px;
}

@media (hover: hover) {
    .card__content {
      transform: translateY(62%);
      transition: transform 500ms ease-out;
      transition-delay: 500ms;
    }

    .card__title::after {
      opacity: 0;
      transform: scaleX(0);
      transition: opacity 1000ms ease-in, transform 500ms ease-out;
      transition-delay: 500ms;
      transform-origin: right;
    }
  
    .card__background {
      transition: transform 500ms ease-in;
    }
  
    .card__content--container > :not(.card__title),
    .card__button {
      opacity: 0;
      transition: transform 500ms ease-out, opacity 500ms ease-out;
    }
  
    .card:hover,
    .card:focus-within {
      transform: scale(1.05);
      transition: transform 500ms ease-in;
    }
  
    .card:hover .card__content,
    .card:focus-within .card__content {
      transform: translateY(0);
      transition: transform 500ms ease-in;
    }
  
    .card:focus-within .card__content {
      transition-duration: 0ms;
    }
  
    .card:hover .card__background,
    .card:focus-within .card__background {
      transform: scale(1.3);
    }
  
    .card:hover .card__content--container > :not(.card__title),
    .card:hover .card__button,
    .card:focus-within .card__content--container > :not(.card__title),
    .card:focus-within .card__button {
      opacity: 1;
      transition: opacity 500ms ease-in;
      transition-delay: 1000ms;
    }
  
    .card:hover .card__title::after,
    .card:focus-within .card__title::after {
      opacity: 1;
      transform: scaleX(1);
      transform-origin: left;
      transition: opacity 500ms ease-in, transform 500ms ease-in;
      transition-delay: 500ms;
    }
  }
  
  @media (max-width:2000px) and (hover: none) {
    .card__content {
        transform: translateY(62%);
        transition: transform 500ms ease-out;
        transition-delay: 500ms;
      }
    
      .card__title::after {
        opacity: 0;
        transform: scaleX(0);
        transition: opacity 1000ms ease-in, transform 500ms ease-out;
        transition-delay: 500ms;
        transform-origin: right;
      }
    
      .card__background {
        transition: transform 500ms ease-in;
      }
    
      .card__content--container > :not(.card__title),
      .card__button {
        opacity: 0;
        transition: transform 500ms ease-out, opacity 500ms ease-out;
      }
    
      .card:hover,
      .card:focus-within {
        transform: scale(1.05);
        transition: transform 500ms ease-in;
      }
    
      .card:hover .card__content,
      .card:focus-within .card__content {
        transform: translateY(0);
        transition: transform 500ms ease-in;
      }
    
      .card:focus-within .card__content {
        transition-duration: 0ms;
      }
    
      .card:hover .card__background,
      .card:focus-within .card__background {
        transform: scale(1.3);
      }
    
      .card:hover .card__content--container > :not(.card__title),
      .card:hover .card__button,
      .card:focus-within .card__content--container > :not(.card__title),
      .card:focus-within .card__button {
        opacity: 1;
        transition: opacity 500ms ease-in;
        transition-delay: 1000ms;
      }
    
      .card:hover .card__title::after,
      .card:focus-within .card__title::after {
        opacity: 1;
        transform: scaleX(1);
        transform-origin: left;
        transition: opacity 500ms ease-in, transform 500ms ease-in;
        transition-delay: 500ms;
      }
}