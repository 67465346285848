@import "~react-image-gallery/styles/css/image-gallery.css";

@import "~react-image-gallery/styles/scss/image-gallery.scss";
.suite{
    margin-top: 20px;
    margin: auto;
    width: 600px;
    
    margin-left: 20px;
}
:root {
    --gold: #ffb338;
    --light-shadow: #77571d;
    --dark-shadow: #3e2904;
  }
.wrapper {
    display: grid;
    grid-template-areas: 'overlap';
    place-content: center;
    text-transform: uppercase;
      }
  .wrapper > div {
    
    background-clip: text;  
    -webkit-background-clip: text;
    font-family: 'Poppins', sans-serif;
    font-weight: 100;
    font-size: clamp( 3em, 4vw, 25rem);
    grid-area: overlap;
    letter-spacing: 1px;
    -webkit-text-stroke: 4px transparent;
  }
  .worth{
    margin: 120px;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  div.bg {
    background-image: repeating-linear-gradient( 105deg, 
      var(--gold) 100% , 
      var(--dark-shadow) 8%,
      var(--gold) 1200%);
    color: transparent;
    filter: drop-shadow(5px 15px 15px black);
    transform: scaleY(1.05);
    transform-origin: top;
  }
  div.fg{
    background-image: repeating-linear-gradient( 5deg,  
      var(--gold) 0% , 
      var(--light-shadow) 193%, 
      var(--gold) 39%);
    color:#ffffff;
    transform: scale(1);
    width: 100%;
  }
.suites{
    display: flex;
    flex-direction: row;
}
body{
    background-color: rgba(3,46,48,255);

}
@media only screen and (max-width:1115px) {
.suite{
    margin: auto;
    width: 100%;  
    position: relative;
    z-index: 0;
}
.worth{
margin:0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
.suites{
    display: flex;
    flex-direction: column;
}
.wrapper > div {
width: 100%;
}
}