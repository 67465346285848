

.swiper {
    width: 100%;
    position: relative;
    z-index: -1;
  
  }
  
  .swiper-slide {
    background-position: center;
    background-size: cover;
    width:100%;
  
    height: 300px;
  }
  
  .swiper-slide img {
    display: block;
    width: 100%;
  
    height: 100%;
  }
  .table{
     width: 95%;
     align-items: center;
     margin: 2.5%;
     justify-content: center;
     
   
  }  
  .tablei{
    width: 95%;
    align-items: center;
    margin: 2.5%;
    
  
 }  
 .tableo{
  width: 95%;
  margin: 2.5%;
  

}  
  @media screen and (max-width:900px){
    .table{
      width: 70%;
      display: flex;
      margin:15%;
      align-items: center;
      justify-content: center;
      
    
   }
   .tablei{
    width: 90%;
      display: flex;
      margin:5%;
      align-items: center;
      overflow-x: auto;
      overflow-y: hidden;
    
  
 }  
 .tableo{
  width: 90%;
    display: flex;
    margin:5%;

    height: 600px;
    overflow-x: auto;
    overflow-y: auto;
  

} 
  
  } 