

.swiper {
  width: 100%;
  position: relative;
  z-index: -1;

}

.swiper-slide {
  background-position: center;
  background-size: cover;
  width: 400px;

  height: 300px;
}

.swiper-slide img {
  display: block;
  width: 100%;

  height: 100%;
}

.h1{
    text-align: center;
padding: 20px;
    margin-top:30px;
    font-family: 'Times New Roman',serif;
    letter-spacing: 2px;
    font-size: 30px;
    font-weight: bold;
    background-image: linear-gradient(
     to right,
     #b07a33 0,
     #c98e36 22%, 
#cfba55 45%,
#ceac50 50%,
#c6a040 55%,
#c78b32 78%,
#a37335 100%
     );
    color:transparent;
    -webkit-background-clip:text;
}
@media only screen and (max-width:900px){



.swiper {
    width: 100%;
  }
  
  .swiper-slide {
    background-position: center;
    background-size: cover;
    width: 350px;
    height: 300px;
  }
  
  .swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
  }
  
  .h1{
      text-align: center;
  padding: 20px;
      margin-top:30px;
      font-family: 'Times New Roman',serif;
      letter-spacing: 2px;
      font-size: 30px;
      font-weight: bold;
      background-image: linear-gradient(
       to right,
       #b07a33 0,
       #c98e36 22%, 
  #cfba55 45%,
  #ceac50 50%,
  #c6a040 55%,
  #c78b32 78%,
  #a37335 100%
       );
      color:transparent;
      -webkit-background-clip:text;
  }
}