.carousel {
  position: relative;
  z-index: -1;
}
.contentStyle {
  width: max-content;
}
.carousel {
  margin-top: -110px;
  margin-bottom: 20px;
}
.swiper {
  width: 100%;
  height: 300px;
  position: sticky;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;

  /* Center slide text vertically */
  display: flex;
  justify-content: center;
  align-items: center;
}

.booking {
  margin-bottom: 100px;
  justify-content: center;
  display: flex;
  height: 380px;
  margin: 10px;
  border-radius: 10px;
  width: 70%;
  background-image: url("hotel9.jpg");
  background-size: cover;
  background-repeat: no-repeat;
}
.bookingo {
  display: flex;
  justify-content: center;
  align-items: center;
}
.bookingi {
  margin-top: 250px;
  outline: none;
  background-size: 150% 150%;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23),
    inset 0 -2px 5px 1px #b17d10, inset 0 -1px 1px 3px rgba(250, 227, 133, 1);
  border: 1px solid rgba(165, 93, 7, 0.6);
  color: rgba(120, 50, 5, 0.8);

  display: inline-block;
  outline: none;
  font-family: inherit;
  font-size: 1em;
  font-weight: bold;
  box-sizing: border-box;
  border: none;
  border-radius: 0.8em;
  height: 2.75em;
  line-height: 2.5em;
  text-transform: uppercase;
  padding: 0 2em;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(110, 80, 20, 0.4),
    inset 0 -2px 5px 1px rgba(139, 66, 8, 1),
    inset 0 -1px 1px 3px rgba(250, 227, 133, 1);
  background-image: linear-gradient(
    160deg,
    #a54e07,
    #b47e11,
    #fef1a2,
    #bc881b,
    #a54e07
  );
  border: 1px solid #a55d07;
  color: rgb(120, 50, 5);
  text-shadow: 0 2px 2px rgba(250, 227, 133, 1);
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  background-size: 100% 100%;
  background-position: center;
  transition: 0.6s;
}

.aio {
  width: 50%;
}

@media screen and (max-width: 900px) {
  .contentStyle {
    width: 50%;
    height: 300px;
    margin-top: 100px;
  }
  .booking {
    width: 100%;
  }
}
