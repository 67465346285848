html {
    font-family: sans-serif;
    box-sizing: border-box;
  }
  
  *, *:before, *:after {
    box-sizing: inherit;
  }
  
  .text-center {
    text-align: center;
  }

  
  .color-white {
    color: #fff;
  }
  
  .box-container {
    
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    padding: 35px 10px;
    width: 100%;
    
  }
  .flip-box{
    margin: 2px;
  }
  
  @media screen and (max-width:880px) {
    .box-container {
      flex-direction: column
    }
  }
  
  .box-item {

    position: relative;
    -webkit-backface-visibility: hidden;
    width: 415px;
    margin-bottom: 35px;
    max-width: 100%;
  }
  h3{
 
    margin-top:30px;
    font-family: 'Times New Roman',serif;
    letter-spacing: 3px;
    font-size: 40px;
    font-weight: bold;
    background-image: linear-gradient(
     to right,
     #b07a33 0,
     #c98e36 22%, 
#cbaf1f 45%,
#ceac50 50%,
#c6a040 55%,
#c78b32 78%,
#a37335 100%
     );
    color:transparent;
    -webkit-background-clip:text;
  }
  
  .flip-box {
    -ms-transform-style: preserve-3d;
    transform-style: preserve-3d;
    -webkit-transform-style: preserve-3d;
    perspective: 1000px;
    -webkit-perspective: 1000px;
  }
  
  .flip-box-front,
  .flip-box-back {
    background-size: cover;
    background-position: center;
    border-radius: 8px;
    min-height: 475px;
    -ms-transition: transform 0.7s cubic-bezier(.4,.2,.2,1);
    transition: transform 0.7s cubic-bezier(.4,.2,.2,1);
    -webkit-transition: transform 0.7s cubic-bezier(.4,.2,.2,1);
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
  }
  
  .flip-box-front {
    -ms-transform: rotateY(0deg);
    -webkit-transform: rotateY(0deg);
    transform: rotateY(0deg);
    -webkit-transform-style: preserve-3d;
    -ms-transform-style: preserve-3d;
    transform-style: preserve-3d;
  }
  
  .flip-box:hover .flip-box-front {
    -ms-transform: rotateY(-180deg);
    -webkit-transform: rotateY(-180deg);
    transform: rotateY(-180deg);
    -webkit-transform-style: preserve-3d;
    -ms-transform-style: preserve-3d;
    transform-style: preserve-3d;
  }
  
  .flip-box-back {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    
    -ms-transform: rotateY(180deg);
    -webkit-transform: rotateY(180deg);
    transform: rotateY(180deg);
    -webkit-transform-style: preserve-3d;
    -ms-transform-style: preserve-3d;
    transform-style: preserve-3d;
  }
  
  .flip-box:hover .flip-box-back {
    -ms-transform: rotateY(0deg);
    -webkit-transform: rotateY(0deg);
    transform: rotateY(0deg);
    -webkit-transform-style: preserve-3d;
    -ms-transform-style: preserve-3d;
    transform-style: preserve-3d;
  }
  
  .flip-box .inner {
    position: absolute;
    left: 0;
    width: 100%;
    padding: 60px;
    outline: 1px solid transparent;
    -webkit-perspective: inherit;
    perspective: inherit;
    z-index: 2;
    
    transform: translateY(-50%) translateZ(60px) scale(.94);
    -webkit-transform: translateY(-50%) translateZ(60px) scale(.94);
    -ms-transform: translateY(-50%) translateZ(60px) scale(.94);
    top: 50%;
  }
  
  .flip-box-header {
    font-size: 34px;
  }
  
  .flip-box p {
    font-size: 20px;
    line-height: 1.5em;
  }
  
  .flip-box-img {
    margin-top: 25px;
  }
  
  .flip-box-button {
    background-color: transparent;
    border: 2px solid #fff;
    border-radius: 2px;
    color: #fff;
    cursor: pointer;
    font-size: 20px;
    font-weight: bold;
    margin-top: 25px;
    padding: 15px 20px;
    text-transform: uppercase;
  }